// @flow
import React from 'react';
import Layout from '../components/layout';
import route from '../routes';
import { DEFAULT_IMAGE, DEFAULT_IMAGE_ALT } from '../utils/globals';

export default class _404 extends React.Component<{}> {
  render() {
    return (
      <Layout
        frontMatter={{
          id: '404',
          title: 'NOT FOUND',
          route: route.page_404,
          preview: 'Medium quality canyoneering information',
          image: DEFAULT_IMAGE,
          image_alt: DEFAULT_IMAGE_ALT,
          parent: 'root',
          children: [],
        }}
      >
        <p>You just hit a route that doesn't exist... the sadness.</p>
      </Layout>
    );
  }
}
